@import "styles/partials/variables";

.App {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}

.App > main {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.App > main.home {
  padding-left: 0;
  padding-right: 0;
}

.new-world-button {
  position: fixed;
  display: block;
  bottom: 1.25em;
  right: 1.25em;
  padding: 0.125em;
  margin: 0;
  z-index: 99999999;
  border: 1px solid $dark-gray;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: $blue;
  box-shadow: 1px 1px 5px 1px $dark-gray;
}
.new-world-button a {
  color: $white;
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: inherit;
  font-size: 3em;
  width: 1em;
  height: 1em;
  line-height: 1;
  font-weight: 100;
}

.errors-container {
  position: fixed;
  top: 55px;
  right: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 0.25em;
  background-color: rgba($white, 0.96);
  z-index: 9999999999;
}
.error-indicator {
  display: block;
  box-sizing: border-box;
  position: relative;
  padding: 0.5em;
  margin: 0.5em 0;
}

.error-indicator .close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border: 1px solid #f50000;
  color: #000;
  display: inline-block;
  appearance: none;
  webkit-appearance: none;
  padding: 0.25em 0.375em;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .4s ease-in-out;
}

.error-indicator .close:hover {
  background-color: #f50000;
  color: $white;
  cursor: pointer;
}

.error-indicator.error {
  background: rgba(243, 35, 35, 0.15);
  color: #f50000;
}

.error-indicator p {
  color: inherit;
  margin: 0.5em 0;
}


/* activity indicator */
.activity-indicator {
  position: fixed;
  padding: 0.25em 0.5em;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 1em;
  color: $white;
  min-width: 35%;
  max-width: 70%;
  bottom: 5%;
  left: 5%;
  z-index: 99999999999;
  transition: all .4s ease-in-out;
}

.activity-indicator p {
  color: inherit;
}


/*social-sharing-dialog*/
.social-sharing-dialog {
  position: fixed;
  bottom: 5%;
  right: 5%;
  margin-bottom: 2.5em;
  border: $border;
  border-radius: 0.25em;
  padding: 0.5em;
  z-index: 999;
  background-color: $white;
  width: 280px;
  overflow: hidden;
}

.social-sharing-dialog .close-dialog {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  color: $dark-gray;
}

.social-sharing-dialog .social-sharing-dialog-header {
  text-align: left;
  // padding: 0.5em 0.25em;
}

.social-sharing-dialog .sharing-options {
  // border-top: $border;
  padding-top: 1em;
  overflow: auto;
  height: 100%;
}

.SocialMediaShareButton {
  display: inline-block;
  margin: 0.375em;
}

/* Expandable Content */

.Expandable-Container {
  border-bottom: $border;
}

.Expandable-Container .clickable-title {
  cursor: pointer;
  padding: 1.5em 1em;
}
.Expandable-Container .clickable-title h3 {
  display: inline-block;
  border-bottom: 1px solid $dark-gray;
  margin: 0;
}



.Expandable-Container.open .expandable-content {
  background-color: #f6f6f6;
}

.Expandable-Container .expandable-content {
  display: block;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
  padding: 1em 0.25em;
}

.Expandable-Container .expandable-content input,
.Expandable-Container .expandable-content textarea,
.Expandable-Container .expandable-content label {
  /*margin: 0.5em 0;*/
}

/* table header */
.table-header {
  border-bottom: $border;
  font-weight: bold;
  padding: 1em 0;
}

.table-header .table-header-cell  {
  overflow: auto;
  border-right: $border;
  font-weight: 900;

}
.table-header .table-header-cell:last-child {
  border-right: 0;
}


/* table row */
.table-row {
  border-bottom: $border;
  font-weight: bold;
  padding: 0.5em 0;
}
.table-row:nth-child(even) {
  background-color: #f6f6f6;
}

.table-row .table-row-cell  {
  overflow: auto;
  /*border-right: $border;*/

}
.table-row .table-row-cell:last-child {
  border-right: 0;
}