
.sub-nav {
	margin: 0;
	padding: 0.25em 0;
}

.sub-nav .link {
	margin: 0 0 0 1em;
}

.sub-nav .link.active,
.sub-nav .link:hover,
.sub-nav .link:active,
.sub-nav .link:focus {
	color: #aaa;
	border-color: #aaa;
}