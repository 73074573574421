.search-bar {
	position: relative;
	display: block;
	box-sizing: border-box;
	// border: 1em solid #444;

	form {
		max-width: 100%;
	}
	.search-bar-field {
		margin: 0;
		border-radius: 0.5em;
		border-width: 0.1em;
		border-color: #efefef;
		padding: 0.25em 1em;
	}

	button {
		position: absolute;
		margin: 0;
		height: 100%;
		z-index: 99;
		top: 0;
		padding: 0 0;
		box-sizing: border-box;
		text-transform: none;

		&.reset {
			right: 0;
			width: 2em;
			height: 2em;
			top: 50%;
			margin-top: -1em;
			right: 0.5em;
			border:0;
			color: #ccc;
		}

		&.submit {
			right: 3em;
			padding-left: 1em;
			padding-right: 1em;
		}
	}

	* {
		outline: 0;
	}
}