.Drag-N-Drop {
	display: block;
	box-sizing: border-box;
	cursor: pointer;

	.dnd-box {
		display: block;
		text-align: center;
		border: 2px dashed #ccc;
		box-sizing: border-box;
		padding: 10% 5%;
	}

	&.drag-active {
		opacity: 0.6;
		outline: blue;
	}
}