.New-World {
	form {
		max-width: 100%;
	}

	.toggle-forms {
		// text-align: right;

		.toggle-forms-button {
			margin: 1em 0.5em;
		}
	}
}

.Multiple-Worlds-Form {
	span {
		font-size: 90%;
		color: #888;
	}
	.multiple-worlds-form {
		padding-bottom: 1em;
		margin-bottom: 3em;
		border-bottom: 1px solid #ccc;

		.Drag-N-Drop {
			margin-bottom: 1em;
		}
	}
}