/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #444;
  line-height: 1.3; }

/*colors*/
/* blue : rgb 48 35 174 */
/* light blue : rgb 83 160 253 */
/* green : rgb 180 236 81 */
/* red : rgb 186 119 102 */
/* shadows : rgb 11 15 60 0.5 */
img {
  display: inline-block;
  border: 0;
  max-width: 100%;
  height: auto; }

/* TYpography */
h1 {
  font-size: 2.125em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: #222; }

h2 {
  font-size: 1.75em;
  margin-top: 2.5em;
  margin-bottom: 1.25em; }

h3 {
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em; }

h4 {
  font-size: 1.25em;
  font-weight: 700;
  margin-top: 1.75em;
  margin-bottom: 1em; }

h5 {
  font-size: 1.175em;
  font-family: Open Sans;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1em; }

p {
  font-size: 1.125em;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.45; }

i {
  font-style: italic; }

b, strong {
  font-family: Open Sans;
  font-weight: 900; }

ol, ul {
  margin-left: 2em;
  margin-bottom: 2em; }
  ol li, ul li {
    font-size: 1.125em;
    margin-bottom: 0.5em;
    list-style: square; }

pre {
  display: block;
  overflow: auto;
  background-color: #efefef;
  padding: 0.5em 0.5em 1.5em 0.5em; }

code {
  padding: 0.025em 0.25em;
  background-color: #f4f4f4; }

blockquote {
  position: relative;
  display: block;
  padding: 1em 2em 1em 1em;
  max-width: 700px;
  margin: 0 auto; }
  blockquote p {
    padding-left: 2em;
    border-left: 2px solid #444;
    font-size: 120%; }
  blockquote cite {
    display: block;
    text-align: right;
    color: #888;
    font-size: 90%;
    font-style: italic; }

.page-content a:not(.btn),
.link {
  color: #000;
  display: inline-block;
  text-decoration: none;
  padding-bottom: 0.15em;
  border-bottom: 1px solid #000;
  transition: all .4s ease-in-out;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  cursor: pointer; }
  .page-content a:not(.btn).warning,
  .link.warning {
    color: #da5320;
    border-color: #da5320; }

/* From elements */
form {
  max-width: 600px;
  margin: 0 auto; }

.btn,
input[type="button"],
input[type="submit"] {
  font-size: 1em;
  color: #000;
  display: inline-block;
  transition: all .4s ease-in-out;
  text-decoration: none;
  appearance: none;
  webkit-appearance: none;
  padding: 1em 2.5em;
  text-transform: uppercase;
  margin: 2em 0 1.75em 0;
  background-color: #FFFFFF; }
  .btn.small,
  input[type="button"].small,
  input[type="submit"].small {
    font-size: 0.75em;
    padding: 0.5em 1.5em; }
  .btn.large,
  input[type="button"].large,
  input[type="submit"].large {
    font-size: 1.125em;
    padding: 1.25em 3.5em; }
  .btn.primary,
  input[type="button"].primary,
  input[type="submit"].primary {
    background-color: #3863e4;
    color: #f4f4f4; }
  .btn.secondary,
  input[type="button"].secondary,
  input[type="submit"].secondary {
    background-color: #874ca0;
    color: #f4f4f4; }
  .btn.cta,
  input[type="button"].cta,
  input[type="submit"].cta {
    background-color: #56c5bb;
    color: #f4f4f4; }
  .btn.warning,
  input[type="button"].warning,
  input[type="submit"].warning {
    background-color: #da5320;
    color: #FFFFFF;
    border-color: #da5320; }
  .btn.disabled,
  input[type="button"].disabled,
  input[type="submit"].disabled {
    background-color: #f4f4f4;
    color: #ccc;
    border-color: #ccc;
    opacity: 0.6; }

.InputField.checkbox {
  position: relative;
  padding-left: 2em; }
  .InputField.checkbox input {
    position: absolute;
    top: 0.5em;
    left: 0.5em; }
