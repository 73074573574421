

// Colors ==
$white: #FFFFFF;
$off-white: #f4f4f4;
$light-gray: #efefef;
$gray: #ccc;
$dark-gray: #444;
$black: #222;
$transparent: rgba(255, 255, 255, 0);
$dark-blue: rgb(28, 48, 70);
$blue: #3863e4;
$purple: #874ca0;
$green: #56c5bb;
$red: #da5320;
$bright-blie: #007AFF;

// Borders ==
$border: 1px solid $gray;

// Fonts ==
$font-family: 'Raleway', sans-serif;
$font-size: 16px;
$font-weight: 300;
$font-height: 1.3;

@mixin base-font {
	font-family: $font-family;
  font-size: $font-size;
	font-weight: $font-weight;
	color: $dark-gray;
	line-height: $font-height;
}