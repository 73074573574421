/*ListWorlds*/
.List-Worlds-Container {
  .search-wrapper {

    .filter-wrapper {
      padding: 0.5em;
    }
  }
}

.List-Worlds {
  display: block;

  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .world {
    width: 90%;
    margin: 5%;
  }

  &.inline {
    white-space: nowrap;
    overflow: auto;

    .world {
      display: inline-block;
      vertical-align: top;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}



@media screen and (min-width: 600px) {
  .List-Worlds-Container {
    .search-wrapper {
      .filter-wrapper {
        float: left;
      }
    }
  }
  .List-Worlds {

    .world {
      width: 29.33%;
      float: left;
      margin: 2%;
    }

    &.inline {

      .world {
        width: 33.33%;
        float: none;
        &:first-child {
          margin-left: 4%;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {

  .List-Worlds:not(.inline) {
    &.cols2 .world {
      width: 50%;
    }
  }
    &.cols4 .world {
      width: 25%;
    }
}