.App {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden; }

.App > main {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto; }

.App > main.home {
  padding-left: 0;
  padding-right: 0; }

.new-world-button {
  position: fixed;
  display: block;
  bottom: 1.25em;
  right: 1.25em;
  padding: 0.125em;
  margin: 0;
  z-index: 99999999;
  border: 1px solid #444;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #3863e4;
  box-shadow: 1px 1px 5px 1px #444; }

.new-world-button a {
  color: #FFFFFF;
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: inherit;
  font-size: 3em;
  width: 1em;
  height: 1em;
  line-height: 1;
  font-weight: 100; }

.errors-container {
  position: fixed;
  top: 55px;
  right: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 0.25em;
  background-color: rgba(255, 255, 255, 0.96);
  z-index: 9999999999; }

.error-indicator {
  display: block;
  box-sizing: border-box;
  position: relative;
  padding: 0.5em;
  margin: 0.5em 0; }

.error-indicator .close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  border: 1px solid #f50000;
  color: #000;
  display: inline-block;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  webkit-appearance: none;
  padding: 0.25em 0.375em;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .4s ease-in-out; }

.error-indicator .close:hover {
  background-color: #f50000;
  color: #FFFFFF;
  cursor: pointer; }

.error-indicator.error {
  background: rgba(243, 35, 35, 0.15);
  color: #f50000; }

.error-indicator p {
  color: inherit;
  margin: 0.5em 0; }

/* activity indicator */
.activity-indicator {
  position: fixed;
  padding: 0.25em 0.5em;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 1em;
  color: #FFFFFF;
  min-width: 35%;
  max-width: 70%;
  bottom: 5%;
  left: 5%;
  z-index: 99999999999;
  transition: all .4s ease-in-out; }

.activity-indicator p {
  color: inherit; }

/*social-sharing-dialog*/
.social-sharing-dialog {
  position: fixed;
  bottom: 5%;
  right: 5%;
  margin-bottom: 2.5em;
  border: 1px solid #ccc;
  border-radius: 0.25em;
  padding: 0.5em;
  z-index: 999;
  background-color: #FFFFFF;
  width: 280px;
  overflow: hidden; }

.social-sharing-dialog .close-dialog {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  color: #444; }

.social-sharing-dialog .social-sharing-dialog-header {
  text-align: left; }

.social-sharing-dialog .sharing-options {
  padding-top: 1em;
  overflow: auto;
  height: 100%; }

.SocialMediaShareButton {
  display: inline-block;
  margin: 0.375em; }

/* Expandable Content */
.Expandable-Container {
  border-bottom: 1px solid #ccc; }

.Expandable-Container .clickable-title {
  cursor: pointer;
  padding: 1.5em 1em; }

.Expandable-Container .clickable-title h3 {
  display: inline-block;
  border-bottom: 1px solid #444;
  margin: 0; }

.Expandable-Container.open .expandable-content {
  background-color: #f6f6f6; }

.Expandable-Container .expandable-content {
  display: block;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
  padding: 1em 0.25em; }

.Expandable-Container .expandable-content input,
.Expandable-Container .expandable-content textarea,
.Expandable-Container .expandable-content label {
  /*margin: 0.5em 0;*/ }

/* table header */
.table-header {
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  padding: 1em 0; }

.table-header .table-header-cell {
  overflow: auto;
  border-right: 1px solid #ccc;
  font-weight: 900; }

.table-header .table-header-cell:last-child {
  border-right: 0; }

/* table row */
.table-row {
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  padding: 0.5em 0; }

.table-row:nth-child(even) {
  background-color: #f6f6f6; }

.table-row .table-row-cell {
  overflow: auto;
  /*border-right: $border;*/ }

.table-row .table-row-cell:last-child {
  border-right: 0; }

.avatar-wrapper {
  display: inline-block; }

.world {
  position: relative; }
  .world .edit-world {
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: 1.5%;
    right: 1.5%;
    z-index: 999;
    cursor: pointer;
    color: #fff;
    border-color: #fff;
    opacity: 1;
    border: 1px solid;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 50%;
    padding: 0.25em 0.125em 0.1em; }
    .world .edit-world img {
      max-width: 1.75em;
      display: block; }

/*ListWorlds*/
.List-Worlds-Container .search-wrapper .filter-wrapper {
  padding: 0.5em; }

.List-Worlds {
  display: block; }
  .List-Worlds:before, .List-Worlds:after {
    content: '';
    display: table;
    clear: both; }
  .List-Worlds .world {
    width: 90%;
    margin: 5%; }
  .List-Worlds.inline {
    white-space: nowrap;
    overflow: auto; }
    .List-Worlds.inline .world {
      display: inline-block;
      vertical-align: top;
      margin-top: 0;
      margin-bottom: 0; }

@media screen and (min-width: 600px) {
  .List-Worlds-Container .search-wrapper .filter-wrapper {
    float: left; }
  .List-Worlds .world {
    width: 29.33%;
    float: left;
    margin: 2%; }
  .List-Worlds.inline .world {
    width: 33.33%;
    float: none; }
    .List-Worlds.inline .world:first-child {
      margin-left: 4%; } }

@media screen and (min-width: 1024px) {
  .List-Worlds:not(.inline).cols2 .world {
    width: 50%; }
  .cols4 .world {
    width: 25%; } }

.search-bar {
  position: relative;
  display: block;
  box-sizing: border-box; }
  .search-bar form {
    max-width: 100%; }
  .search-bar .search-bar-field {
    margin: 0;
    border-radius: 0.5em;
    border-width: 0.1em;
    border-color: #efefef;
    padding: 0.25em 1em; }
  .search-bar button {
    position: absolute;
    margin: 0;
    height: 100%;
    z-index: 99;
    top: 0;
    padding: 0 0;
    box-sizing: border-box;
    text-transform: none; }
    .search-bar button.reset {
      right: 0;
      width: 2em;
      height: 2em;
      top: 50%;
      margin-top: -1em;
      right: 0.5em;
      border: 0;
      color: #ccc; }
    .search-bar button.submit {
      right: 3em;
      padding-left: 1em;
      padding-right: 1em; }
  .search-bar * {
    outline: 0; }

.world-uploader-indicator {
  position: relative;
  margin-bottom: 1em;
  box-sizing: border-box; }
  .world-uploader-indicator .world-uploader {
    padding: 0.5em;
    border: 1px solid #cccccc;
    position: relative;
    z-index: 2; }
    .world-uploader-indicator .world-uploader .header {
      text-align: center; }
    .world-uploader-indicator .world-uploader .uploader-links {
      margin: 0.5em 0;
      text-align: right; }
      .world-uploader-indicator .world-uploader .uploader-links .edit, .world-uploader-indicator .world-uploader .uploader-links .view {
        margin: 0 0.5em;
        padding: 0.25em 0;
        border-bottom: 1px solid #444; }
    .world-uploader-indicator .world-uploader input {
      margin: 0; }
    .world-uploader-indicator .world-uploader .edit, .world-uploader-indicator .world-uploader .view {
      bottom: 0.125em;
      right: 0.3em;
      font-size: 85%; }
    .world-uploader-indicator .world-uploader .view {
      right: 2.675em; }
  .world-uploader-indicator.uploading .indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-animation: 1.5s ease-in-out 0s uploading infinite;
            animation: 1.5s ease-in-out 0s uploading infinite; }
  .world-uploader-indicator.done .indicator {
    display: none; }
  .world-uploader-indicator.failed {
    background: #c73131; }
    .world-uploader-indicator.failed .indicator {
      display: none; }
    .world-uploader-indicator.failed .world-uploader {
      color: #f4f4f4; }

@-webkit-keyframes uploading {
  0% {
    background: #3853a6;
    opacity: 1; }
  50% {
    background: #3853a6;
    opacity: 0.4; }
  100% {
    background: #3853a6;
    opacity: 1; } }

@keyframes uploading {
  0% {
    background: #3853a6;
    opacity: 1; }
  50% {
    background: #3853a6;
    opacity: 0.4; }
  100% {
    background: #3853a6;
    opacity: 1; } }

@-webkit-keyframes uploaded {
  0% {
    background: #3b7500;
    opacity: 1; }
  50% {
    background: #3b7500;
    opacity: 0.4; }
  100% {
    background: #3b7500;
    opacity: 1; } }

@keyframes uploaded {
  0% {
    background: #3b7500;
    opacity: 1; }
  50% {
    background: #3b7500;
    opacity: 0.4; }
  100% {
    background: #3b7500;
    opacity: 1; } }

.Drag-N-Drop {
  display: block;
  box-sizing: border-box;
  cursor: pointer; }
  .Drag-N-Drop .dnd-box {
    display: block;
    text-align: center;
    border: 2px dashed #ccc;
    box-sizing: border-box;
    padding: 10% 5%; }
  .Drag-N-Drop.drag-active {
    opacity: 0.6;
    outline: blue; }

.New-World form {
  max-width: 100%; }

.New-World .toggle-forms .toggle-forms-button {
  margin: 1em 0.5em; }

.Multiple-Worlds-Form span {
  font-size: 90%;
  color: #888; }

.Multiple-Worlds-Form .multiple-worlds-form {
  padding-bottom: 1em;
  margin-bottom: 3em;
  border-bottom: 1px solid #ccc; }
  .Multiple-Worlds-Form .multiple-worlds-form .Drag-N-Drop {
    margin-bottom: 1em; }



.App main.edit-world {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  overflow: hidden;
  padding-top: 0; }

.Edit-World {
  position: relative;
  height: 100vh;
  box-sizing: border-box; }

.edit-world-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding-bottom: 4em;
  width: 85%;
  box-sizing: border-box;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.95); }

.edit-world-sidebar.close {
  width: 0; }

.edit-world-sidebar .toggle-sidebar {
  cursor: pointer;
  position: absolute;
  top: 7em;
  left: 0;
  width: 2em;
  height: 2.5em;
  margin-left: -2em;
  z-index: 99;
  padding-top: 0.5em;
  box-sizing: border-box;
  border-radius: 0.125em 0 0 0.125em;
  background-color: rgba(255, 255, 255, 0.95);
  text-align: center; }

.edit-world-sidebar .toggle-sidebar .toggle-bar {
  height: 0.125em;
  margin: 0.4em 0.35em;
  background-color: #444;
  border-radius: 0.25em;
  display: block; }

.edit-world-sidebar .inner-sidebar {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 2em;
  box-sizing: border-box; }

.edit-world-sidebar .inner-sidebar .reload-button {
  text-align: center; }

.edit-world-preview {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/icons/360Icon@2x.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center; }

.edit-world-preview.full-width {
  width: 100%; }

.new-world-form {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%; }
  .new-world-form .Drag-N-Drop {
    margin-bottom: 20px; }

.delete-world,
.new-world {
  margin-top: 5em;
  padding: 0.5em; }
  .delete-world .link,
  .new-world .link {
    text-align: center;
    border-bottom: 0; }

.delete-world {
  margin-bottom: 5em; }

.edit-navButton,
.edit-tooltip {
  padding: 0.5em;
  border-bottom: 1px solid #ccc; }

.edit-navButton h4,
.edit-tooltip h4 {
  margin: 0.5em 0; }

.edit-navButtons .empty-message,
.edit-tooltips .empty-message {
  padding: 0.5em; }

.edit-navButton input,
.edit-tooltip input,
.edit-navButton textarea,
.edit-tooltip textarea,
.edit-navButton button,
.edit-tooltip button {
  margin: 0.5em 0; }

.delete-tooltip-button,
.delete-navButton-button {
  text-align: right; }

.delete-tooltip-button .link,
.delete-navButton-button .link {
  margin-top: 2em; }

.new-navButton-button,
.new-tooltip-button {
  margin-top: 1em; }

.new-world-form form {
  margin-bottom: 5em; }

.new-world-form .main-info {
  padding: 0 0.5em; }

/* Edit Mode */
.Edit-Mode {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.Edit-Mode > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.media-preview {
  padding-top: 54.6%;
  position: relative; }

.media-preview > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

/* responsive styles */
@media screen and (min-width: 800px) {
  .edit-world-sidebar {
    width: 35%; }
  /*.control-buttons {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25%;
  }
  .edit-world-sidebar.close .control-buttons {
    display: none;
  }*/ }

.registration-form * {
  outline: 0; }

.user-page-avatar {
	font-size: 3em;
	text-align: center;
	padding-top: 0.5em;
}
.avatar-wrapper {
  display: block;
  margin: 0 auto;
  max-width: 96px; }

.org-logo {
	position: relative;
	margin: 2em auto;
	padding: 0.5em;
	display: block;
	max-width: 15em;
	box-sizing: border-box;
	border-radius: 0.5em;
	box-shadow: 0 0 1px 1px #ccc;
	background-color: #fefefe;
}

.org-logo-figure {
	width: 10em;
	height: 10em;
	display: block;
	margin: 0 auto;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}


.sub-nav {
	margin: 0;
	padding: 0.25em 0;
}

.sub-nav .link {
	margin: 0 0 0 1em;
}

.sub-nav .link.active,
.sub-nav .link:hover,
.sub-nav .link:active,
.sub-nav .link:focus {
	color: #aaa;
	border-color: #aaa;
}
.new-user-btn {
	float: right;
	font-size: medium;
	font-size: initial;
	margin-top: 1em;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #444;
  line-height: 1.3; }

/*colors*/
/* blue : rgb 48 35 174 */
/* light blue : rgb 83 160 253 */
/* green : rgb 180 236 81 */
/* red : rgb 186 119 102 */
/* shadows : rgb 11 15 60 0.5 */
img {
  display: inline-block;
  border: 0;
  max-width: 100%;
  height: auto; }

/* TYpography */
h1 {
  font-size: 2.125em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: #222; }

h2 {
  font-size: 1.75em;
  margin-top: 2.5em;
  margin-bottom: 1.25em; }

h3 {
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em; }

h4 {
  font-size: 1.25em;
  font-weight: 700;
  margin-top: 1.75em;
  margin-bottom: 1em; }

h5 {
  font-size: 1.175em;
  font-family: Open Sans;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1em; }

p {
  font-size: 1.125em;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.45; }

i {
  font-style: italic; }

b, strong {
  font-family: Open Sans;
  font-weight: 900; }

ol, ul {
  margin-left: 2em;
  margin-bottom: 2em; }
  ol li, ul li {
    font-size: 1.125em;
    margin-bottom: 0.5em;
    list-style: square; }

pre {
  display: block;
  overflow: auto;
  background-color: #efefef;
  padding: 0.5em 0.5em 1.5em 0.5em; }

code {
  padding: 0.025em 0.25em;
  background-color: #f4f4f4; }

blockquote {
  position: relative;
  display: block;
  padding: 1em 2em 1em 1em;
  max-width: 700px;
  margin: 0 auto; }
  blockquote p {
    padding-left: 2em;
    border-left: 2px solid #444;
    font-size: 120%; }
  blockquote cite {
    display: block;
    text-align: right;
    color: #888;
    font-size: 90%;
    font-style: italic; }

.page-content a:not(.btn),
.link {
  color: #000;
  display: inline-block;
  text-decoration: none;
  padding-bottom: 0.15em;
  border-bottom: 1px solid #000;
  transition: all .4s ease-in-out;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  outline: 0;
  cursor: pointer; }
  .page-content a:not(.btn).warning,
  .link.warning {
    color: #da5320;
    border-color: #da5320; }

/* From elements */
form {
  max-width: 600px;
  margin: 0 auto; }

.btn,
input[type="button"],
input[type="submit"] {
  font-size: 1em;
  color: #000;
  display: inline-block;
  transition: all .4s ease-in-out;
  text-decoration: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  webkit-appearance: none;
  padding: 1em 2.5em;
  text-transform: uppercase;
  margin: 2em 0 1.75em 0;
  background-color: #FFFFFF; }
  .btn.small,
  input[type="button"].small,
  input[type="submit"].small {
    font-size: 0.75em;
    padding: 0.5em 1.5em; }
  .btn.large,
  input[type="button"].large,
  input[type="submit"].large {
    font-size: 1.125em;
    padding: 1.25em 3.5em; }
  .btn.primary,
  input[type="button"].primary,
  input[type="submit"].primary {
    background-color: #3863e4;
    color: #f4f4f4; }
  .btn.secondary,
  input[type="button"].secondary,
  input[type="submit"].secondary {
    background-color: #874ca0;
    color: #f4f4f4; }
  .btn.cta,
  input[type="button"].cta,
  input[type="submit"].cta {
    background-color: #56c5bb;
    color: #f4f4f4; }
  .btn.warning,
  input[type="button"].warning,
  input[type="submit"].warning {
    background-color: #da5320;
    color: #FFFFFF;
    border-color: #da5320; }
  .btn.disabled,
  input[type="button"].disabled,
  input[type="submit"].disabled {
    background-color: #f4f4f4;
    color: #ccc;
    border-color: #ccc;
    opacity: 0.6; }

.InputField.checkbox {
  position: relative;
  padding-left: 2em; }
  .InputField.checkbox input {
    position: absolute;
    top: 0.5em;
    left: 0.5em; }

