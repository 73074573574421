.world {
  position: relative;

  .edit-world {
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: 1.5%;
    right: 1.5%;
    z-index: 999;
    cursor: pointer;
    color: #fff;
    border-color: #fff;
    opacity: 1;
    border: 1px solid;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 50%;
    padding: 0.25em 0.125em 0.1em;

    img {
      max-width: 1.75em;
      display: block;
    }
  }
}