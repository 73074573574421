@import "../../../styles/partials/variables";

.App main.edit-world {
	padding-left: 0;
	padding-right: 0;
	max-width: 100%;
  overflow: hidden;
  padding-top: 0;
}

.Edit-World {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
}

.edit-world-sidebar {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	padding-bottom: 4em;
	width: 85%;
	box-sizing: border-box;
  z-index: 9;
  background-color: rgba($white, 0.95);
}
.edit-world-sidebar.close {
  width: 0;
}

.edit-world-sidebar .toggle-sidebar {
  cursor: pointer;
  position: absolute;
  top: 7em;
  left: 0;
  width: 2em;
  height: 2.5em;
  margin-left: -2em;
  z-index: 99;
  padding-top: 0.5em;
  box-sizing: border-box;
  border-radius: 0.125em 0 0 0.125em;
  background-color: rgba($white, 0.95);
  text-align: center;
}

.edit-world-sidebar .toggle-sidebar .toggle-bar {
  height: 0.125em;
  margin: 0.4em 0.35em;
  background-color: $dark-gray;
  border-radius: 0.25em;
  display: block;
}

.edit-world-sidebar .inner-sidebar {
	overflow-y: auto;
  height: 100%;
  padding-bottom: 2em;
  box-sizing: border-box;
}

.edit-world-sidebar .inner-sidebar .reload-button {
  text-align: center;
}

.edit-world-preview {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/icons/360Icon@2x.png');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.edit-world-preview.full-width {
  width: 100%;
}



.new-world-form {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%;

  .Drag-N-Drop {
    margin-bottom: 20px;

    // .dnd-box {
    //   padding: 10vh 8vw;
    // }
  }
}

.delete-world,
.new-world {
  margin-top: 5em;
  padding: 0.5em;

  .link {
    text-align: center;
    border-bottom: 0;
  }
}

.delete-world {
  margin-bottom: 5em;
}


.edit-navButton,
.edit-tooltip {
  padding: 0.5em;
  border-bottom: $border;
}
.edit-navButton h4,
.edit-tooltip h4 {
  margin: 0.5em 0;
}
.edit-navButtons .empty-message,
.edit-tooltips .empty-message {
  padding: 0.5em;
}

.edit-navButton input,
.edit-tooltip input,
.edit-navButton textarea,
.edit-tooltip textarea,
.edit-navButton button,
.edit-tooltip button {
  margin: 0.5em 0;
}

.delete-tooltip-button,
.delete-navButton-button {
  text-align: right;
}
.delete-tooltip-button .link,
.delete-navButton-button .link {
  margin-top: 2em;
}

.new-navButton-button,
.new-tooltip-button {
  margin-top: 1em;
}
// .new-navButton-button > input[type="button"],
// .new-tooltip-button > input[type="button"] {
//   margin: 0;
//   display: block;
//   width: 100%;
//   box-sizing: border-box;
// }

.new-world-form {
  form {
    margin-bottom: 5em;
  }

  .main-info {
    padding: .0.5em;
  }
}

/* Edit Mode */

.Edit-Mode {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.Edit-Mode > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}


.media-preview {
  padding-top: 54.6%;
  position: relative;
}
.media-preview > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* responsive styles */
@media screen and (min-width: 800px) {
  .edit-world-sidebar {
    width: 35%;
  }

  .edit-world-preview {
    // width: 75%;
  }

  /*.control-buttons {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25%;
  }
  .edit-world-sidebar.close .control-buttons {
    display: none;
  }*/
}