$blue: #3853a6;
$green: #3b7500;
$red: #c73131;
$white: #f4f4f4;
$gray: #cccccc;

.world-uploader-indicator {
	position: relative;
	margin-bottom: 1em;
	box-sizing: border-box;

	.world-uploader {
		padding: 0.5em;
		border: 1px solid $gray;
		position: relative;
		z-index: 2;

		.header {
			text-align: center;
		}

		.uploader-links {
			margin: 0.5em 0;
			text-align: right;

			.edit,.view {
				margin: 0 0.5em;
				padding: 0.25em 0;
				border-bottom: 1px solid #444;
			}
		}

		input {
			margin: 0;
		}

		.edit,.view {
			// position: absolute;
			bottom: 0.125em;
			right: 0.3em;
			// color: $white;
			font-size: 85%;
		}
		.view {
			right: 2.675em;
		}
	}

	&.uploading {
		.indicator {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			animation: 1.5s ease-in-out 0s uploading infinite;
		}
	}

	&.done {

		.indicator {
			display: none;
		}

		.world-uploader {
			// color: $white;
		}
	}

	&.failed {
		background: $red;

		.indicator {
			display: none;
		}

		.world-uploader {
			color: $white;
		}
	}
}


@keyframes uploading {
  0% {
    background: $blue;
    opacity: 1;
    // transform: scale(1);
  }

  50% {
    // background: darken($blue, 10%);
    background: $blue;
    opacity: 0.4;
    // transform: scale(1.015);
  }

  100% {
    background: $blue;
    opacity: 1;
    // transform: scale(1);
  }
}

@keyframes uploaded {
  0% {
    background: $green;
    opacity: 1;
    // transform: scale(1);
  }

  50% {
    // background: darken($green, 10%);
    background: $green;
    opacity: 0.4;
    // transform: scale(1.015);
  }

  100% {
    background: $green;
    opacity: 1;
    // transform: scale(1);
  }
}