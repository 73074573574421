.org-logo {
	position: relative;
	margin: 2em auto;
	padding: 0.5em;
	display: block;
	max-width: 15em;
	box-sizing: border-box;
	border-radius: 0.5em;
	box-shadow: 0 0 1px 1px #ccc;
	background-color: #fefefe;
}

.org-logo-figure {
	width: 10em;
	height: 10em;
	display: block;
	margin: 0 auto;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}
